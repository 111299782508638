import AxiosService from "@/services/AxiosService";

class PostbackService {
  getPostback(payload) {
    return AxiosService.get("/user/postback-logs", { params: payload })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getPlatformsPostback(payload, id) {
    return AxiosService.get(`/subaccount/${id}/postback-logs`, {
      params: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new PostbackService();
